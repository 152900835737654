/* Node Modules */

/* Utils */
import { useMemoCompare } from '@/utils/hooks/optimization/react';
import { difference } from 'lodash';
import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

export const getTickerLastPriceFromLifeCycle = (modelLifeCycles: any) => {
    const _instruments = modelLifeCycles?.map(({ instrument }: any) => instrument?.instrumentToken);
    const instruments = useMemoCompare(_instruments, (prev: any, next: any) => {
        return prev && prev.length === next.length && prev.every((item: any, index: number) => item === next[index]);
    });

    const { tickerLastPriceData } = useTickerData(instruments);
    return tickerLastPriceData;
};

// Make this reusable in a way to keep this at the global level, also updating the render when it requires
// Use atom and store ticker price
// Give instance update to things it requests
export const useTickerData = (instruments: any): any => {
    const [processedInstrument, setProcessedInstrument] = useState([]);
    const memoizedInstruments = useMemoCompare(instruments, (prev: any, next: any) => {
        return prev && prev.length === next.length && prev.every((item: any, index: number) => item === next[index]);
    });

    //Public API that will echo messages sent to it back to the client
    const socketUrl = 'wss://aventador.investmint.club/websocket';
    const [tickerLastPriceData, setTickerLastPriceData] = useState<any>({});

    const { sendMessage, lastMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl, {
        share: true,
        retryOnError: true,
        shouldReconnect: () => true,
    });

    useEffect(() => {
        const lastMessageData = lastMessage ? JSON.parse(lastMessage?.data) : null;
        if (lastMessageData === null) return;
        const { instrument_token, last_price } = lastMessageData;
        if (tickerLastPriceData[instrument_token] !== last_price) {
            setTickerLastPriceData((prevData: any) => ({
                ...prevData,
                [instrument_token]: last_price,
            }));
        }
    }, [lastMessage]);

    useEffect(() => {
        const instrumentsToBeProcessed = difference(memoizedInstruments, processedInstrument);
        sendMessage(
            JSON.stringify({
                tokens: instrumentsToBeProcessed.map((item) => Number(item)),
                action: 'subscribe',
            }),
        );
        setProcessedInstrument([...processedInstrument, ...instrumentsToBeProcessed]);
    }, [memoizedInstruments]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    return { tickerLastPriceData, connectionStatus };
};
