import styled from 'styled-components';

type TBtnProps = {
    type: 'primary';
    disabled: boolean;
};

export const Button = styled.button<TBtnProps | any>`
    padding-left: 12px;
    padding-right: 12px;
    height: 36px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #eceef4;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? '.6' : '1')};
    ${(props) =>
        props.type === 'primary' ? 'background: #5667FF; color: white; box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.05); border: 1px solid #eceef4;' : ''};

    ${(props) => (props.type === 'secondary' ? 'color:#202124; border: 1px solid #202124; ' : '')};

    border-radius: 8px;

    :hover {
        opacity: 0.8;
    }
`;

export const MobileAdaptableButton = styled(Button)<any>`
    @media screen and (max-width: 680px) {
        width: 100%;
        height: 48px;
        max-width: 380px;
        font-size: 14px;
    }
`;
