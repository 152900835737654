/* Node Modules */
import {AxiosResponse} from 'axios'

/* Utils */
import api, {fetchAccessTokenAndSetHeaders} from 'Utils/fetchUtils'
import {getAPIHostUrl} from 'Utils/urlUtils'

/* Types */
import {
    Broker,
    BrokerLoginUrlResponse,
    BrokerOrderPlaceResp,
    ExtraOrderCancellationRequest,
    InitBrokerOrderRequest,
    InitBrokerOrderResponse,
    InvesBrokerPlaceOrder,
    InvesBrokerPlaceOrderResponse,
    MarginInfo,
    OrderDetailsByTag,
    PunchBrokerOrder,
    SmallcaseBroker,
    SmallcaseGatewayTxnSuccessResponse,
    SmallcaseOrderResp,
    SmallcaseTransactionInfo,
    SmallcaseTransactionResponse,
    UserBrokerInformationMappingRequest,
    UserBrokerInformationMappingResponse,
    UserBrokers
} from 'Types/broker'

export const fetchAllBrokers = async () => {
    fetchAccessTokenAndSetHeaders()
    const url = `${getAPIHostUrl()}/api/v1/brokers`
    const {data} = await api.get<Broker[]>(url)
    return data
}

export const fetchUserBrokers = async () => {
    fetchAccessTokenAndSetHeaders()
    const url = `${getAPIHostUrl()}/api/v1/user-broker/brokers`
    const {data} = await api.get<AxiosResponse<UserBrokers>>(url)
    return data
}

export const fetchMarginRequired = async (
    brokerOrderInfo: PunchBrokerOrder
) => {
    fetchAccessTokenAndSetHeaders()
    const url = `${getAPIHostUrl(true)}/api/v1/broker/getMargin`
    const {data} = await api.post<PunchBrokerOrder, AxiosResponse<MarginInfo>>(
        url,
        brokerOrderInfo
    )
    return data
}

export const getBrokerLoginUrl = async (brokerUuid: string) => {
    fetchAccessTokenAndSetHeaders()
    const url = `${getAPIHostUrl(
        true
    )}/api/v1/user-broker/${brokerUuid}/login-url`
    const {data} = await api.get<AxiosResponse<BrokerLoginUrlResponse>>(url)
    return data
}

export const getBrokerByUserId = () => {}

export const getSmallcaseBrokerByUserId =
    async (): Promise<SmallcaseBroker> => {
        fetchAccessTokenAndSetHeaders()
        const url = `${getAPIHostUrl(true)}/api/v1/smallcase/broker`
        const {data} = await api.get(url)
        return data
    }

export const setSmallcaseUserBroker = async (
    brokerInfo: SmallcaseBroker
): Promise<SmallcaseBroker> => {
    fetchAccessTokenAndSetHeaders()

    const url = `${getAPIHostUrl(true)}/api/v1/smallcase/broker`
    const {data} = await api.post(url, brokerInfo)
    return data
}

export const getTransactionId = async (
    transactionInfo: SmallcaseTransactionInfo
): Promise<SmallcaseTransactionResponse> => {
    fetchAccessTokenAndSetHeaders()
    const url = `${getAPIHostUrl(true)}/api/v1/smallcase/transaction`
    console.log('==============')
    console.log(transactionInfo)
    const {data} = await api.post(url, transactionInfo)
    console.log('Response', data)
    console.log('==============')
    return data
}

export const punchSmallcaseOrder = async (
    smallcaseTxnResp: SmallcaseGatewayTxnSuccessResponse
): Promise<SmallcaseOrderResp> => {
    fetchAccessTokenAndSetHeaders()
    const {transactionId} = smallcaseTxnResp || {}
    const url = `${getAPIHostUrl(true)}/api/v1/smallcase/place`
    const data = await api.post(url, smallcaseTxnResp)
    // Intentionally leaving log
    console.log('punchSmallcaseOrder data ', data)
    const orderData = data?.data
    return {
        orderData,
        orderParameters: {transactionId, isSmallcaseExecutionFlow: true}
    }
}

export const punchInvesBrokerOrder = async (
    brokerOrderInfo: InitBrokerOrderRequest
) => {
    console.log('=====ORDER PAYLOAD======', JSON.stringify(brokerOrderInfo))
    fetchAccessTokenAndSetHeaders()
    const url = `${getAPIHostUrl(true)}/api/v1/user-broker/place-order`
    const data = await api.post<
        InvesBrokerPlaceOrderResponse,
        AxiosResponse<UserBrokerInformationMappingResponse>
    >(url, brokerOrderInfo)
    return data?.data
}

export const postUserInformationToBroker = async (
    userBrokerDetails: UserBrokerInformationMappingRequest
) => {
    fetchAccessTokenAndSetHeaders()
    const url = `${getAPIHostUrl(true)}/api/v1/user-broker/user-information`
    const data = await api.post<
        UserBrokerInformationMappingRequest,
        AxiosResponse<UserBrokerInformationMappingResponse>
    >(url, userBrokerDetails)

    return data?.data
}

export const fetchCancelExtraBrokerOrder = async (
    orderDetails: ExtraOrderCancellationRequest
) => {
    fetchAccessTokenAndSetHeaders()
    const url = `${getAPIHostUrl(true)}/api/v1/user-broker/cancel-order`
    const data = await api.post<
        ExtraOrderCancellationRequest,
        AxiosResponse<InvesBrokerPlaceOrder>
    >(url, orderDetails)

    return data?.data
}

export const fetchBrokerOrderInitData = async (
    orderDetails: InitBrokerOrderRequest
) => {
    fetchAccessTokenAndSetHeaders()
    const url = `${getAPIHostUrl(true)}/api/v1/user-broker/order-init`
    const data = await api.post<
        InitBrokerOrderRequest,
        AxiosResponse<InitBrokerOrderResponse>
    >(url, orderDetails)

    return data?.data
}

export const fetchOrderDetailsByTag = async (tagDetails: OrderDetailsByTag) => {
    const {tag, brokerId, showBrokerReadOnlyAccessModal} = tagDetails
    const url = `${getAPIHostUrl(true)}/api/v1/user-broker/${
        brokerId || null
    }/orders/tag/${tag}`
    const {data} = await api.get<AxiosResponse<BrokerOrderPlaceResp[]>>(url)
    return {data, showBrokerReadOnlyAccessModal, tag, brokerId}
}

export const deleteSmallCaseBroker = async smallcaseBrokerInfo => {
    const url = `${getAPIHostUrl(true)}/api/v1/smallcase/disconnect-broker`
    const data = await api.delete<Record<string, string>, AxiosResponse<any>>(
        url,
        {
            data: smallcaseBrokerInfo
        }
    )

    return data?.data
}

export const fetchAMOResponseFromBroker = async (
    actionDetails: Record<string, string>
) => {
    const url = `${getAPIHostUrl(true)}/api/v1/smallcase/sync-orders`
    const {data} = await api.post<Record<string, string>, AxiosResponse<any>>(
        url,
        actionDetails
    )
    return data
}

export const fetchSyncInvesBrokerOrder = async (
    tagsDetails: Record<string, string[]>
) => {
    const url = `${getAPIHostUrl(true)}/api/v1/user-broker/sync-orders`
    const {data} = await api.post<Record<string, string[]>, AxiosResponse<any>>(
        url,
        tagsDetails
    )
    return data
}

export const fetchUserBrokerValidation = async (userBrokerId: string) => {
    const url = `${getAPIHostUrl(true)}/api/v1/user-broker/validate-user-access`
    const {data} = await api.post<
        string,
        AxiosResponse<Record<string, boolean>>
    >(url, {userBrokerId})
    return data
}

export const setPreferredBrokerId = async (userBrokerId: string) => {
    const url = `${getAPIHostUrl(true)}/api/v1/user-broker/preferred-broker`
    const {data} = await api.put<
        string,
        AxiosResponse<Record<string, boolean>>
    >(url, {userBrokerId})
    return data
}
